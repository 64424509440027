import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./screens/Home";
import Product from "./Product";

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Home />}>

              </Route>
              <Route path="product" element={<Product/>} />
          </Routes>
      </BrowserRouter>

  );
}

export default App;
