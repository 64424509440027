import logo from "../logo.svg";
import {Link} from "react-router-dom";
import {Navbar} from "../components/HomeCompontents/Navbar/Navbar";
import Button from "../components/Button";
import {Slider} from "../components/HomeCompontents/Slider/SliderComp";


const Home = () => {
    return (
        <section>
            <Navbar/>
            <Slider/>
            <div>

            </div>
        </section>
    );
};

export default Home;
{/*<div className="text-xl">*/}
{/*    <Button/>*/}
{/*</div>*/}

// <Link to="/product">
//     <a
//         className="App-link"
//
//         target="_blank"
//         rel="noopener noreferrer"
//     >
//         Learn React
//     </a>
// </Link>