import React, {useState} from "react";
import Logo from "../../../assets/logoname.svg"
import {Link} from "react-router-dom";
import NavLinks from "./NavLinks";
import Button from "../../Button";
import {COLORS} from "../../../utils/colors";
import {IonIcon} from "@ionic/react";
import {close, menu} from "ionicons/icons";

const Navbar = () => {
    const [open, setOpen] = useState(false)
  return(
     <nav  style={{background: COLORS.bgColor}}>
         <div className= "flex items-center font-medium justify-around">
             <div style={{ zIndex: 9999,}} className="z-50 p-5 md:w-auto w-full flex justify-between">
                 <img src={Logo} alt ="logo" style={{height:60,width:200}} className="md:cursor-pointer h-12"/>
                 <div className="text-3xl md:hidden" style={{color:COLORS.black}} onClick={()=>setOpen(!open)}>
                     <IonIcon icon={open ? close : menu}/>
                 </div>
             </div>
             <ul className="md:flex hidden uppercase items-center gap-8 font-[Poppins]" >
                 <li>
                     <Link to= "/" className="py-7 px-3 inline-block">
                         Home
                     </Link>
                 </li>
                 <NavLinks/>
             </ul>
             <div className="md:block hidden">
                 <Button name="dsf"/>
             </div>

             {/*mobile nav*/}
             <ul
                 style={{ zIndex: 9999,}}
             className={'md:hidden bg-white absolute w-full h-full bottom-0  ' +
                 `duration-100 ${open ? "left-0": "left-[-100%]"}`}>
                 <div style={{ zIndex: 9999,background: COLORS.bgColor}} className="z-50 p-5 md:w-auto w-full flex justify-between">
                     <img src={Logo} alt ="logo" style={{height:60,width:200}} className="md:cursor-pointer h-12"/>
                     <div className="text-3xl md:hidden" style={{color:COLORS.black}} onClick={()=>setOpen(!open)}>
                         <IonIcon icon={open ? close : menu}/>
                     </div>
                 </div>

               <div  className={'md:hidden bg-white py-5  pl-4'}>
                   <li>
                       <Link to= "/" className="py-7 px-3 inline-block">
                           Home
                       </Link>
                   </li>
                   <NavLinks/>
                   <div className="py-5">
                       <Button/>
                   </div>
               </div>
             </ul>
         </div>
     </nav>
  )
}

export {
    Navbar
}