import React from "react";
import {COLORS} from "../utils/colors";

const Button = () => {
  return(
      <button
          style={{background:COLORS.buttonColorPink, color:COLORS.buttonColorRed}}
          className='text-black px-6 py-2 rounded-full'>
          Get Started
      </button>
  )
}

export default Button
