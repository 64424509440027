import React, {useState} from "react";
import {Link} from "react-router-dom";
import {COLORS} from "../../../utils/colors";
import {links} from "./Mylinks";
import {IonIcon} from "@ionic/react";
import {chevronUp, chevronDown, close, menu} from "ionicons/icons";

const NavLinks = () => {
    const [heading, setHeading] = useState("");
    const [subHeading, setSubHeading] = useState("");

    return <>{links.map((link) => (<div>
            <div  className="px-3 text-left md:cursor-pointer group">
                <h1 className="py-7 flex justify-between items-center md:pr-0 pr-5 group" onClick={() => {
                    heading !== link.name ? setHeading(link.name) : setHeading("");
                    setSubHeading("");
                }}>{link.name}


                         <span className="text-xl md:hidden inline"> { link.name !== "Gift & Gold Coin" ?     <IonIcon icon={heading === link.name ? chevronUp : chevronDown}/> : <></> }


              </span>
                    <span
                        className="text-xl md:mt-1 md:ml-2  md:block hidden group-hover:rotate-180 group-hover:-mt-2">
               { link.name !== "Gift & Gold Coin" ?  <IonIcon icon={chevronDown}/> : <></> }
              </span>
                </h1>
                {link.submenu && (<div style={{ zIndex: 9999,}}>
                        <div style={{ zIndex: 9999,}} className="absolute top-30 hidden group-hover:md:block hover:md:block ">
                            <div className="py-3">

                                <div className="w-4 h-4 left-3 absolute mt-1 bg-white rotate-45 border-2 border-hovercolor">

                                </div>

                            </div>
                            <div style={{ zIndex: 9999,}}  className="bg-white p-5 grid grid-cols-3 gap-10 rounded-md  border-radius: 0.75rem shadow-lg shadow-hovercolor/40 border-2 border-hovercolor">
                                {link.sublinks.map((mysublinks) => (<div>
                                        <h1 className="text-lg font-semibold">{mysublinks.Head}</h1>
                                        {mysublinks.sublink.map((slink) => (
                                            <li className="text-sm text-gray-600 my-2.5">
                                                <Link to={slink.link}
                                                      className="hover:text-hovercolor">
                                                    {slink.name}
                                                </Link>
                                            </li>))}
                                    </div>))}
                            </div>

                        </div>
                    </div>)}
            </div>


            {/*mobile*/}
            <div style={{ zIndex: 9999,}} className={`
            ${heading === link.name ? "md:hidden" : "hidden"}
          `}>
                {/* sublinks */}
                {link.sublinks?.map((slinks) => (<div>
                        <div style={{ zIndex: 9999,}}>
                            <h1 onClick={() => subHeading !== slinks.Head ? setSubHeading(slinks.Head) : setSubHeading("")}
                                className="py-4 pl-7 font-semibold md:pr-0 pr-5 flex justify-between items-center md:pr-0 pr-5">
                                {slinks.Head}
                                <span className="text-xl md:mt-1 md:ml-2 inline">
                                <IonIcon icon={ subHeading === slinks.Head ? chevronUp : chevronDown}/>
                                </span>

                            </h1>
                            <div style={{ zIndex: 9999,}} className={`${subHeading === slinks.Head ? "md:hidden" : "hidden"}`}>
                                {slinks.sublink?.map((slink) => (<li className="py-3 pl-14">
                                        <Link to={slink.link}>{slink.name}</Link>
                                    </li>))}
                            </div>
                        </div>
                    </div>))}
            </div>

        </div>

    ))

    }</>
}

export default NavLinks;