import React from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import banner1 from '../../../assets/banner1.jpg'
import banner2 from '../../../assets/banner2.jpg'
import banner3 from '../../../assets/banner3.jpg'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import {EffectFade, Navigation, Pagination,Autoplay} from 'swiper';
import {COLORS} from "../../../utils/colors";
import {HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight} from "react-icons/hi";


const data = [{
    img:banner1, name: ""
}, {
    img: banner2, name: ""
}, {
    img: banner3,
    name: ""
},]

export const Slider = () => {
    const width = window.innerWidth
    return (<div style={{zIndex: 1, marginTop: 1}}>
            <Swiper
                // loop={true}
                // autoplay={true}
                className="relative group"
                modules={[Navigation,EffectFade]}
                navigation={{
                    nextEl:".button-next-slide",
                    prevEl: ".button-prev-slide"
                }}
                 effect={"fade"}
                spaceBetween={50}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}>
                {data.map((item) => (<div>
                        <SwiperSlide>
                            <div className="image relative">
                                <img src={item.img} alt="dfcsad"/>
                                {/*<div className="title-content absolute top-[25%] left-[8rem] space-y-5">*/}
                                {/*    <h3 className="text-[16px]">More Luxury & trending</h3>*/}
                                {/*    <h3  style={{fontSize:20}} className=" font-[700]">Elegant designs</h3>*/}
                                {/*    <p className="text-[14px] w-[40%]">You can buy your own items from our jewels and you will be happy while*/}
                                {/*        purchase</p>*/}
                                {/*    <button*/}
                                {/*        style={{background:COLORS.buttonColorPink, color:COLORS.buttonColorRed}}*/}
                                {/*        className='text-black px-6 py-2 rounded-full'>*/}
                                {/*        Shop now*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                            </div>
                        </SwiperSlide>
                    </div>))}

                <div className=" top-[50%] absolute z-10 button-next-slide group-hover:left-0 -left-[23rem] duration-500 text-white w-[40px] h-[40px] bg-black grid place-items-center">
                    <HiOutlineArrowNarrowLeft/>

                </div>
                <div className=" top-[50%] absolute z-10 button-prev-slide group-hover:right-0 -right-[23rem] duration-500 text-white w-[40px] h-[40px] bg-black grid place-items-center">
                    <HiOutlineArrowNarrowRight/>
                </div>
            </Swiper>
        </div>)
}