export  const links = [
    {
        name:'Gold',
        submenu:true,
        sublinks:[
            {
                Head: "Earrings",
                sublink: [
                    {name: "Studs",
                        link:"/"},
                    {name: "Hangings",
                        link:"/"},
                    {name: "Drops",
                        link:"/"},
                    {name: "Jhumkas",
                        link:"/"},
                ]
            },
            {
                Head: "Rings",
                sublink: [
                    {name: "Band Rings",
                        link:"/"},
                    {name: "Engagement Rings",
                        link:"/"},
                    {name: "Casual Rings",
                        link:"/"},
                ]
            },
            {
                Head: "Necklace",
                sublink: [
                    {name: "Short Necklace",
                        link:"/"},
                    {name: "Long Necklace",
                        link:"/"},
                ]
            },
            {
                Head: "Bangle & Bracelets",
                sublink: [
                    {name: "Bangle",
                        link:"/"},
                    {name: "Chain Bracelets",
                        link:"/"},
                    {name: "Cuff Bracelets",
                        link:"/"},
                    {name: "Bangle Bracelets",
                        link:"/"},
                ]
            }
            // {
            //     Head: "Chains",
            // },
        ]

    },
    {name: 'Diamond',
        submenu:true,
        sublinks:[
            {
                Head: "Earrings",
                sublink: [
                    {name: "Studs",
                        link:"/"},
                    {name: "Hangings",
                        link:"/"},
                    {name: "Drops",
                        link:"/"},
                    {name: "Jhumkas",
                        link:"/"},
                ]
            },
            {
                Head: "Rings",
                sublink: [
                    {name: "Band Rings",
                        link:"/"},
                    {name: "Engagement Rings",
                        link:"/"},
                    {name: "Casual Rings",
                        link:"/"},
                ]
            },
            {
                Head: "Necklace",
                sublink: [
                    {name: "Short Necklace",
                        link:"/"},
                    {name: "Long Necklace",
                        link:"/"},
                ]
            },
            {
                Head: "Bangle & Bracelets",
                sublink: [
                    {name: "Bangle",
                        link:"/"},
                    {name: "Chain Bracelets",
                        link:"/"},
                    {name: "Cuff Bracelets",
                        link:"/"},
                    {name: "Bangle Bracelets",
                        link:"/"},
                ]
            },
            // {
            //     Head: "Chains",
            // },
        ]},
    {name: 'Silver',
        submenu:true,
        sublinks:[
            {
                Head: "Earrings",
                sublink: [
                    {name: "Studs",
                        link:"/"},
                    {name: "Hangings",
                        link:"/"},
                    {name: "Drops",
                        link:"/"},
                    {name: "Jhumkas",
                        link:"/"},
                ]
            },
            {
                Head: "Rings",
                sublink: [
                    {name: "Band Rings",
                        link:"/"},
                    {name: "Engagement Rings",
                        link:"/"},
                    {name: "Casual Rings",
                        link:"/"},
                ]
            },
            {
                Head: "Necklace",
                sublink: [
                    {name: "Short Necklace",
                        link:"/"},
                    {name: "Long Necklace",
                        link:"/"},
                ]
            },
            {
                Head: "Bangle & Bracelets",
                sublink: [
                    {name: "Bangle",
                        link:"/"},
                    {name: "Chain Bracelets",
                        link:"/"},
                    {name: "Cuff Bracelets",
                        link:"/"},
                    {name: "Bangle Bracelets",
                        link:"/"},
                ]
            },
            // {
            //     Head: "Chains",
            // },
        ]},
    {name: 'Platinum',
        submenu:true,
        sublinks:[
            {
                Head: "Earrings",
                sublink: [
                    {name: "Studs",
                        link:"/"},
                    {name: "Hangings",
                        link:"/"},
                    {name: "Drops",
                        link:"/"},
                    {name: "Jhumkas",
                        link:"/"},
                ]
            },
            {
                Head: "Rings",
                sublink: [
                    {name: "Band Rings",
                        link:"/"},
                    {name: "Engagement Rings",
                        link:"/"},
                    {name: "Casual Rings",
                        link:"/"},
                ]
            },
            {
                Head: "Necklace",
                sublink: [
                    {name: "Short Necklace",
                        link:"/"},
                    {name: "Long Necklace",
                        link:"/"},
                ]
            },
            {
                Head: "Bangle & Bracelets",
                sublink: [
                    {name: "Bangle",
                        link:"/"},
                    {name: "Chain Bracelets",
                        link:"/"},
                    {name: "Cuff Bracelets",
                        link:"/"},
                    {name: "Bangle Bracelets",
                        link:"/"},
                ]
            },
            // {
            //     Head: "Chains",
            // },
        ]},
    {name: 'Gift & Gold Coin'}
]